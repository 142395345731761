import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import SETTINGS from '../../setting.json';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import './Order.css';
import image1 from '../../assets/images/image1.png';
import image2 from '../../assets/images/image2.png';
import image3 from '../../assets/images/image3.jpg';
import image4 from '../../assets/images/image4.png';
import image5 from '../../assets/images/image5.jpg';
import image6 from '../../assets/images/image6.png';
import image7 from '../../assets/images/image7.png';
import image8 from '../../assets/images/image8.png';
import image9 from '../../assets/images/image9.png';
import image10 from '../../assets/images/image10.png';
import image11 from '../../assets/images/image11.png';
import image12 from '../../assets/images/image12.jpg';
import image13 from '../../assets/images/image13.png';
import image14 from '../../assets/images/image14.png';
import image15 from '../../assets/images/image15.jpg';
import image16 from '../../assets/images/image16.jpg';
import image17 from '../../assets/images/image17.jpg';
import image18 from '../../assets/images/image18.jpg';
import image19 from '../../assets/images/image19.png';
import image20 from '../../assets/images/image20.jpg';
import image21 from '../../assets/images/image21.png';
import image22 from '../../assets/images/image22.jpg';
import image23 from '../../assets/images/image23.png';
import image24 from '../../assets/images/image24.jpg';
import image25 from '../../assets/images/image25.png';
import image26 from '../../assets/images/image26.png';
import image27 from '../../assets/images/image27.png';
import image28 from '../../assets/images/image28.png';
import image29 from '../../assets/images/image29.png';
import image30 from '../../assets/images/image30.png';
import grace from '../../assets/images/grace-bay-beach-turks-caicos-islands.jpg';
import ninhhai from '../../assets/images/bai-bien-ninh-chu-1.jpg';
import lescala from '../../assets/images/lescala.webp';
import montdore from '../../assets/images/montdore.webp';
import celle from '../../assets/images/Celle.jpg';
import wollongong from '../../assets/images/Wollongong.jpg';
import vitri from '../../assets/images/vitri.png';
import logoX from '../../assets/images/logox.png';
import audio from '../../assets/audio/hongbao.mp3';
import Loading from 'react-loading';

const axios = require('axios').default;

function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

function formatMoney(money = 0) {
    return String(money).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ' VNĐ';
}

// const formatter = new Intl.NumberFormat('vi-VN', {
//     style: 'currency',
//     currency: 'VND',
// });

function Order(args) {
    const [isLoading, setIsLoading] = useState(false);
    const [isDisableButton, setIsDisableButton] = useState(true);
    const { t } = useTranslation();
    const [isModalOrder, setIsModalOrder] = useState(false);
    const [order, setOrder] = useState();
    const [isCallApiUser, setIsCallApiUser] = useState(false);
    const [star, setStar] = useState(5);

    const descriptionHome = [
        {
            title: t('content.home.titleVip1'),
            image: ninhhai,
            content: t('content.home.contentVip1'),
        },
        {
            title: t('content.home.titleVip2'),
            image: grace,
            content: t('content.home.contentVip2'),
        },
        {
            title: t('content.home.titleVip3'),
            image: lescala,
            content: t('content.home.contentVip3'),
        },
        {
            title: t('content.home.titleVip4'),
            image: montdore,
            content: t('content.home.contentVip4'),
        },
        {
            title: t('content.home.titleVip5'),
            image: celle,
            content: t('content.home.contentVip5'),
        },
        {
            title: t('content.home.titleVip6'),
            image: wollongong,
            content: t('content.home.contentVip6'),
        },
    ];

    const toggleModalOrder = () => setIsModalOrder(!isModalOrder);

    const currentTime = new Date().getHours();

    useEffect(() => {
        if (currentTime >= 9 && currentTime <= 24) {
            setIsDisableButton(false);
        }
    }, [currentTime]);

    const [user, setUser] = useState({
        data: [{ money: 0, dongbangtk: 0, roses: 0 }],
        moneyEarn: { moneyEarnPreDay: 0, moneyEarnToday: 0 },
        mission: {
            amount: 0,
            result: 0,
            amountToday: 0,
        },
    });

    const navigate = useNavigate();

    const [isMatching, setIsMatching] = useState(false);

    const playAudio = () => {
        new Audio(audio).play();
    };

    const handSendMission = async () => {
        const headers = {
            'x-access-token': localStorage.getItem('auth'),
            'Access-Control-Allow-Origin': '*',
        };

        axios
            .post(
                `${SETTINGS.BASE_URL}/api/webapi/mission/new`,
                { level: '1' },
                {
                    headers,
                },
            )
            .then(async function (response) {
                let data = response.data;
                if (data.status === 'ok') {
                    setIsMatching(false);
                    if (data.data.type === 1) {
                        toast.success(`${t('content.order.timThay1DonHang')}`, { theme: 'light' });
                        setIsModalOrder(true);
                        setOrder(data.data.mission);
                        setStar(data.data.mission.star);
                        return;
                    }

                    if (data.data.type === '1') {
                        toast.success(t('content.order.coDHCHT'), { theme: 'light' });
                        setIsModalOrder(true);
                        setOrder(data.data.mission);
                        setStar(data.data.mission.star);
                        return;
                    }

                    if (data.data.type === 2) {
                        toast.warn(
                            `Hệ thống đã nhận được yêu cầu của bạn và đang xử lí, vui lòng thử lại sau vài phút!`,
                            {
                                theme: 'light',
                            },
                        );
                        return;
                    }

                    if (data.data.type === 3) {
                        toast.warn(`${t('content.order.tkBiKhoa')}`, { theme: 'light' });
                        setTimeout(() => {
                            window.location.reload();
                        }, 1000);
                        return;
                    }
                    if (data.data.type === 6) {
                        toast.success(`${t('content.order.donHangChuaHT')}`, { theme: 'light' });
                        await sleep(1000);
                        setTimeout(() => {
                            navigate(`/order/confirm/${data.data.mission.id_mission}`);
                        }, 1000);
                        return;
                    }
                    if (data.data.type === 1000) {
                        toast.info(`${t('content.order.daHTDuDon')}`, { theme: 'light' });
                        setTimeout(() => {
                            window.location.reload();
                        }, 1000);
                        return;
                    }
                }
            })
            .catch(function (error) {
                setIsMatching(false);
                if (error.response.status === 403)
                    return toast.warn(t('content.order.timeLayDonHang'), { theme: 'light' });
                toast.error(`${t('content.error')}`, { theme: 'light' });
            });
    };

    function confirmMission(id, id_mission) {
        setIsModalOrder(false);
        setIsMatching(true);

        const headers = {
            'x-access-token': localStorage.getItem('auth'),
            'Access-Control-Allow-Origin': '*',
        };
        axios
            .post(
                `${SETTINGS.BASE_URL}/api/webapi/mission/confirm/id`,
                { id: id, id_mission: id_mission },
                {
                    headers,
                },
            )
            .then(async function (response) {
                let data = response.data;

                if (data.status === 'ok') {
                    if (data.data.type === 1000) {
                        setIsMatching(false);
                        return toast.warn(t('content.order.htDDH'), {
                            theme: 'light',
                        });
                    }

                    if (data.data.type === 2) {
                        setIsMatching(false);
                        return toast.warn(`${t('content.confirmOrder.nvKTonTai')}`, { theme: 'light' });
                    }

                    if (data.data.type === 3) {
                        setIsMatching(false);
                        return toast.warn(`${t('content.confirmOrder.daLamNv')}`, { theme: 'light' });
                    }

                    if (data.data.type === 4) {
                        setIsMatching(false);
                        return toast.warn(`${t('content.confirmOrder.capBacKDu')}`, { theme: 'light' });
                    }
                    if (data.data.type === 5) {
                        setIsMatching(false);
                        return toast.warn(`${t('content.confirmOrder.conNvChuaHT')}`, { theme: 'light' });
                    }

                    if (data.data.type === 0) {
                        setIsMatching(false);
                        setIsCallApiUser(!isCallApiUser);
                        return toast.warn(`${t('content.confirmOrder.soDuKDu')}`, { theme: 'light' });
                    }

                    if (data.data.type === 1) {
                        setTimeout(() => {
                            setIsMatching(false);
                        }, 2500);

                        setTimeout(() => {
                            toast.success(`${t('content.confirmOrder.xacNhanDonHangThangCong')}`, { theme: 'light' });
                        }, 3000);

                        setTimeout(() => {
                            setIsCallApiUser(!isCallApiUser);
                        }, 4000);
                    }
                }
            })
            .catch(function (error) {
                if (error.response.status === 403)
                    return toast.warn(t('content.order.timeLayDonHang'), { theme: 'light' });
                toast.error(`${t('content.error')}`, { theme: 'light' });
            });
    }

    useEffect(() => {
        // checkToken();
        setIsLoading(true);
        axios
            .get(`${SETTINGS.BASE_URL}/api/webapi/userInfo`, {
                headers: {
                    'x-access-token': localStorage.getItem('auth'),
                    'Access-Control-Allow-Origin': '*',
                },
            })
            .then(function (response) {
                setTimeout(() => {
                    setIsLoading(false);
                }, 1000);

                let data = response.data;
                if (data.status === 'ok') {
                    setUser(data);
                }
            })
            .catch(function (error) {
                toast.error(t('content.error'), { theme: 'light' });
            });
    }, [isCallApiUser]);

    return (
        <div className="main-container">
            {isMatching && (
                <div className="matching-animation">
                    <div className="overlay">
                        <div className="text flex">{t('content.order.khopTuDong')}</div>
                        <div className="loader loader1"></div>
                        <div className="loader loader2"></div>
                        <div className="loader loader3"></div>
                    </div>
                </div>
            )}

            {isModalOrder && (
                <Modal isOpen={isModalOrder} toggle={toggleModalOrder} {...args} centered>
                    <div className="modal-home-style">
                        <ModalHeader
                            className="relative"
                            style={{
                                background: `url(${order?.image})`,
                                backgroundSize: 'cover',
                                width: '100%',
                                height: '37rem',
                                backgroundRepeat: 'no-repeat',
                            }}
                        >
                            <div className="absolute top-[5px] right-[5px]" onClick={toggleModalOrder}>
                                <img src={logoX} alt="" width={25} height={25} />
                            </div>
                            <div className="goods-wrap">
                                <div className="goods-content flex flex-col items-start justify-center">
                                    <div className="text-goods w-[100%] flex items-center ml-4 mt-2">
                                        <img src={vitri} alt="" width={32} height={32} />
                                        <div className="goods_name text-white font-bold text-2xl">
                                            {order?.name_mission}
                                        </div>
                                    </div>
                                    <div className="goods w-[100%] flex flex-col px-4">
                                        <div className="goods-cell flex items-center justify-between mt-2">
                                            <span className="text-white font-bold text-2xl">
                                                {t('content.order.soTien')}
                                            </span>
                                            <span className="text-white font-bold text-2xl">
                                                {formatMoney(order?.price)}
                                            </span>
                                        </div>
                                        <div className="goods-cell flex items-center justify-between mt-2">
                                            <span className="text-white font-bold text-2xl">
                                                {t('content.order.tiLe')}
                                            </span>
                                            <span className="text-white font-bold text-2xl">
                                                {formatMoney(order?.receive)}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ModalHeader>
                        <ModalBody>
                            {order?.description && (
                                <div className="desc-order__style">
                                    <p className="text-3xl font-bold bg-[#3e85ff] mt-3 p-3 rounded-xl text-white text-center uppercase">
                                        {t('content.order.thongTinCT')}
                                    </p>
                                    <div
                                        dangerouslySetInnerHTML={{ __html: order?.description }}
                                        className="text-lg leading-10"
                                    ></div>
                                </div>
                            )}
                            <div className="rate-box flex ml-2 py-8">
                                <span className="font-bold mt-1" style={{ fontSize: '16px', fontFamily: 'Roboto' }}>
                                    {t('content.order.danhGia')}
                                </span>
                                <ul>
                                    <li className={`${star > 0 ? 'light' : ''}`} onClick={() => setStar(1)}></li>
                                    <li className={`${star > 1 ? 'light' : ''}`} onClick={() => setStar(2)}></li>
                                    <li className={`${star > 2 ? 'light' : ''}`} onClick={() => setStar(3)}></li>
                                    <li className={`${star > 3 ? 'light' : ''}`} onClick={() => setStar(4)}></li>
                                    <li className={`${star > 4 ? 'light' : ''}`} onClick={() => setStar(5)}></li>
                                </ul>
                            </div>

                            <div>
                                <textarea
                                    name=""
                                    id=""
                                    cols="45"
                                    rows="5"
                                    placeholder={t('content.order.chiaSe')}
                                    className="text-2xl w-[100%] p-4 border rounded-xl"
                                ></textarea>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <div
                                className="mx-2 w-[100%] flex items-center justify-center bg-[#5392f9] h-[4.8rem] rounded-lg text-white text-2xl font-semibold"
                                onClick={() => confirmMission(order?.id, order?.id_mission)}
                            >
                                {t('content.order.gui')}
                            </div>
                        </ModalFooter>
                    </div>
                </Modal>
            )}

            <div className="flex items-center justify-between mt-12 w-[100%]">
                <div className="ant-row ant-row-space-between w-[100%]">
                    <div className="ant-col">
                        <div className="sc-ikJyIC flex flex-col items-center justify-center">
                            <div className="sc-hiCibw iYoREV text-white py-2 lg:text-4xl text-3xl mb-4 text-center">
                                {isLoading ? (
                                    <Loading
                                        type="spinningBubbles"
                                        color="white"
                                        height={'20px'}
                                        width={'20px'}
                                        className="mb-3"
                                    />
                                ) : (
                                    formatMoney(user?.data[0].money)
                                )}
                            </div>
                            <div className="sc-jJoQJp hzrkvO text-white text-2xl">{t('content.order.soDuTK')}</div>
                            <div className="sc-hiCibw iYoREV text-white py-2 lg:text-4xl text-3xl mb-4 mt-12 text-center">
                                {isLoading ? (
                                    <Loading
                                        type="spinningBubbles"
                                        color="white"
                                        height={'20px'}
                                        width={'20px'}
                                        className="mb-3"
                                    />
                                ) : (
                                    formatMoney(user.data[0].dongbangtk)
                                )}
                            </div>
                            <div className="sc-jJoQJp hzrkvO text-white text-2xl">{t('content.order.soTienDB')}</div>
                        </div>
                    </div>

                    <div className="ant-col">
                        <div className="sc-ikJyIC flex flex-col items-center justify-center">
                            <div className="sc-hiCibw iYoREV text-white py-2 lg:text-4xl text-3xl mb-4 text-center">
                                {isLoading ? (
                                    <Loading
                                        type="spinningBubbles"
                                        color="white"
                                        height={'20px'}
                                        width={'20px'}
                                        className="mb-3"
                                    />
                                ) : (
                                    formatMoney(user?.moneyEarn.moneyEarnToday)
                                )}
                            </div>
                            <div className="sc-jJoQJp hzrkvO text-white text-2xl">
                                {t('content.order.hoaHongHomNay')}
                            </div>
                            <div className="sc-hiCibw iYoREV text-white py-2 lg:text-4xl text-3xl mb-4 mt-12 text-center">
                                {isLoading ? (
                                    <Loading
                                        type="spinningBubbles"
                                        color="white"
                                        height={'20px'}
                                        width={'20px'}
                                        className="mb-3"
                                    />
                                ) : (
                                    user?.mission?.amountToday
                                )}
                            </div>
                            <div className="sc-jJoQJp hzrkvO text-white text-2xl">
                                {t('content.order.soDonHoanThanh')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="main-container bg-[#ffffff] mt-4 overflow-x-hidden" style={{ borderRadius: '25px' }}>
                <div className="flex items-center mb-2 animate-area-0 animated slideOutLeft infinite mt-4">
                    <img src={image1} alt="" className="dashboard-product-image" />
                    <img src={image2} alt="" className="dashboard-product-image" />
                    <img src={image3} alt="" className="dashboard-product-image" />
                    <img src={image4} alt="" className="dashboard-product-image" />
                    <img src={image5} alt="" className="dashboard-product-image" />
                    <img src={image6} alt="" className="dashboard-product-image" />
                    <img src={image7} alt="" className="dashboard-product-image" />
                    <img src={image8} alt="" className="dashboard-product-image" />
                    <img src={image9} alt="" className="dashboard-product-image" />
                    <img src={image10} alt="" className="dashboard-product-image" />
                    <img src={image11} alt="" className="dashboard-product-image" />
                    <img src={image12} alt="" className="dashboard-product-image" />
                    <img src={image13} alt="" className="dashboard-product-image" />
                    <img src={image14} alt="" className="dashboard-product-image" />
                    <img src={image15} alt="" className="dashboard-product-image" />
                    <img src={image16} alt="" className="dashboard-product-image" />
                    <img src={image17} alt="" className="dashboard-product-image" />
                    <img src={image18} alt="" className="dashboard-product-image" />
                    <img src={image19} alt="" className="dashboard-product-image" />
                    <img src={image20} alt="" className="dashboard-product-image" />
                    <img src={image21} alt="" className="dashboard-product-image" />
                    <img src={image22} alt="" className="dashboard-product-image" />
                    <img src={image23} alt="" className="dashboard-product-image" />
                    <img src={image24} alt="" className="dashboard-product-image" />
                    <img src={image25} alt="" className="dashboard-product-image" />
                    <img src={image26} alt="" className="dashboard-product-image" />
                    <img src={image27} alt="" className="dashboard-product-image" />
                    <img src={image28} alt="" className="dashboard-product-image" />
                    <img src={image29} alt="" className="dashboard-product-image" />
                    <img src={image30} alt="" className="dashboard-product-image" />
                </div>

                <div
                    className="flex items-center justify-center bg-[#5392f9] text-white fs-2 font-semibold rounded-full mt-12 mb-4 mx-4 py-2"
                    onClick={() => {
                        // if (isDisableButton) {
                        //     toast.warn(`${t('content.order.timeLayDonHang')}`, { theme: 'light' });
                        //     return;
                        // }

                        // if (user?.data[0].money < 100) {
                        //     toast.warn(`${t('content.order.soDuKhongDat')}`, { theme: 'light' });
                        //     return;
                        // }

                        setIsMatching(true);
                        handSendMission();
                    }}
                >
                    {t('content.order.ketHopTD')}
                </div>

                <div className="flex items-center justify-center fs-2 font-semibold">Explore Tours</div>

                <div className="flex items-center justify-center description-order my-8 flex-wrap w-[100%]">
                    {descriptionHome.map((item, i) => {
                        return (
                            <div
                                key={i}
                                className="flex flex-col items-center justify-start w-[46%] mx-2 rounded-2xl border mb-4 tours-item"
                            >
                                <img src={item.image} alt="" className="w-[100%]" />
                                <div className="ml-4">
                                    <div className="title fs-4 font-bold">{item.title}</div>
                                    <div className="content-desc fs-5">{item.content}</div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>

            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    );
}

export default Order;
