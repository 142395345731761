import styles from './My.module.scss';
import classNames from 'classnames/bind';
import SETTINGS from '../../setting.json';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsFillCreditCard2FrontFill } from 'react-icons/bs';
import Select from 'react-select';

const axios = require('axios').default;

let listBank = [
    { label: 'EXIMBANK', value: 'EXIMBANK' },
    { label: 'MARITIME BANK', value: 'MARITIME BANK' },
    { label: 'AGRIBANK', value: 'AGRIBANK' },
    { label: 'VIETINBANK', value: 'VIETINBANK' },
    { label: 'BAC A BANK', value: 'BAC A BANK' },
    { label: 'BAO VIET BANK', value: 'BAO VIET BANK' },
    { label: 'BIDV BANK', value: 'BIDV BANK' },
    { label: 'GP BANK', value: 'GP BANK' },
    { label: 'HD BANK', value: 'HD BANK' },
    { label: 'HONGLEONG BANK', value: 'HONGLEONG BANK' },
    { label: 'INDOVINA BANK', value: 'INDOVINA BANK' },
    { label: 'KIENLONGBANK', value: 'KIENLONGBANK' },
    { label: 'MBBANK', value: 'MBBANK' },
    { label: 'NAMA BANK', value: 'NAMA BANK' },
    { label: 'NGAN HANG A CHAU', value: 'NGAN HANG A CHAU' },
    { label: 'Ngân hàng TMCP Đông Á', value: 'Ngân hàng TMCP Đông Á' },
    { label: 'Ngân hàng TMCP Việt Á', value: 'Ngân hàng TMCP Việt Á' },
    { label: 'NH LD VIET NGA', value: 'NH LD VIET NGA' },
    { label: 'CIMB', value: 'CIMB' },
    { label: 'NH TMCP QUOC DAN', value: 'NH TMCP QUOC DAN' },
    { label: 'OCEANBANK', value: 'OCEANBANK' },
    { label: 'PGBANK', value: 'PGBANK' },
    { label: 'PHUONGDONG BANK', value: 'PHUONGDONG BANK' },
    { label: 'SACOMBANK', value: 'SACOMBANK' },
    { label: 'SCB', value: 'SCB' },
    { label: 'SEABANK', value: 'SEABANK' },
    { label: 'SHB BANK', value: 'SHB BANK' },
    { label: 'SHINHAN BANK VN', value: 'SHINHAN BANK VN' },
    { label: 'TECHCOMBANK', value: 'TECHCOMBANK' },
    { label: 'TIENPHONG BANK', value: 'TIENPHONG BANK' },
    { label: 'UNITED OVERSEAS BANK', value: 'UNITED OVERSEAS BANK' },
    { label: 'VIB BANK', value: 'VIB BANK' },
    { label: 'VIDPublic Bank', value: 'VIDPublic Bank' },
    { label: 'VIETBANK', value: 'VIETBANK' },
    { label: 'VIETCOMBANK', value: 'VIETCOMBANK' },
    { label: 'VPBANK', value: 'VPBANK' },
    { label: 'WOORI BANK', value: 'WOORI BANK' },
    { label: 'NEWBANK', value: 'NEWBANK' },
    { label: 'ACB', value: 'ACB' },
    { label: 'OCB', value: 'OCB' },
    { label: 'ABBANK', value: 'ABBANK' },
    { label: 'TPBANK', value: 'TPBANK' },
    { label: 'LIENVIETBANK', value: 'LIENVIETBANK' },
    { label: 'PVBANK', value: 'PVBANK' },
];

const cx = classNames.bind(styles);
const checkToken = () => {
    let accessToken = localStorage.getItem('auth');
    if (!accessToken) {
        localStorage.removeItem('auth');
        window.location.href = '/login';
    } else {
        axios
            .get(`${SETTINGS.BASE_URL}/api/webapi/me`, {
                headers: {
                    'x-access-token': accessToken,
                    'Access-Control-Allow-Origin': '*',
                },
            })
            .then(function (response) {
                let data = response.data;
                if (data.status === 'error') {
                    localStorage.removeItem('auth');
                    window.location.href = '/login';
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
};

function MyBank({ title }) {
    document.title = title;
    let [nameOnwerBank, setNameOnwerBank] = useState('');
    let [stk, setStk] = useState('');
    let [nameBank, setNameBank] = useState();
    let [sdt, setSdt] = useState('');
    let [check, setCheck] = useState('');
    const [isBankConnected, setIsBankConnected] = useState(false);

    const { t } = useTranslation();

    const handleChangeNameBank = (value) => {
        setNameBank(value.value);
    };

    const handleAddBanking = () => {
        if (!nameOnwerBank || !stk || !nameBank || !sdt)
            return toast.error(`${t('content.myBank.nhapDayDuTT')}`, { theme: 'light' });

        const headers = {
            'x-access-token': localStorage.getItem('auth'),
            'Access-Control-Allow-Origin': '*',
        };
        axios
            .post(
                `${SETTINGS.BASE_URL}/api/webapi/user/addbanking`,
                { nameuser: nameOnwerBank, stk, nameBank, sdt, type: 'add' },
                {
                    headers,
                },
            )
            .then(function (response) {
                let data = response.data;
                if (data.data === 1) toast.success(`${t('content.myBank.themNganHang')}`, { theme: 'light' });
                if (data.data === 2) toast.success(`${t('content.myBank.suaNganHang')}`, { theme: 'light' });

                if (data.data === 3) {
                    toast.error(`${t('content.myBank.stkTonTai')}`, {
                        theme: 'light',
                    });
                    return;
                }

                if (data.status) {
                    setTimeout(() => {
                        window.location.href = `/my`;
                    }, 1500);
                }
            })
            .catch(function (error) {
                toast.error(`${t('content.error')}`, { theme: 'light' });
            });
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        checkToken();
        axios
            .get(`${SETTINGS.BASE_URL}/api/webapi/user/banking`, {
                headers: {
                    'x-access-token': localStorage.getItem('auth'),
                    'Access-Control-Allow-Origin': '*',
                },
            })
            .then(function (response) {
                let data = response.data;
                if (data.data.recharge.length > 0) {
                    setCheck(data.data);
                    setNameOnwerBank(data.data.recharge[0].name_u_bank);
                    setSdt(data.data.recharge[0].username);
                    setStk(data.data.recharge[0].stk_bank);
                    if (data.data.recharge[0].name_bank) {
                        setNameBank(data.data.recharge[0].name_bank);
                    }
                    if (data.data.recharge[0].stk_bank) {
                        setIsBankConnected(true);
                    }
                }
            })
            .catch(function (error) {
                toast.error(`${t('content.error')}`, { theme: 'light' });
            });
    }, []);

    return (
        <div className="px-[15px] py-[20px] h-[550px] bg-[white] rounded-3xl mt-24">
            <div className={cx('edit-password', { '': true })}>
                {check && (
                    <div className="form-edit-password p-[20px]">
                        <div
                            className={`${cx(
                                'bank-header',
                            )} text-3xl font-medium flex items-center justify-start text-black`}
                        >
                            <BsFillCreditCard2FrontFill size={24} color="#5392f9" className="mr-4" />
                            {t('content.myBank.taiKhoanNH')}
                        </div>
                        <div className={cx('form-group', { '': true })}>
                            <label className={cx('bank-title')}>{t('content.myBank.hoTen')}</label>
                            <input
                                disabled={check.recharge[0].name_u_bank}
                                onChange={(e) => setNameOnwerBank(e.target.value)}
                                className="w-[100%]"
                                placeholder={t('content.myBank.hoten')}
                                defaultValue={check.recharge[0].name_u_bank || ''}
                            />
                        </div>

                        <div className={cx('form-group', { '': true })}>
                            <label className={cx('bank-title')}>{t('content.myBank.SDT')}</label>
                            <input
                                disabled={check.recharge[0].username}
                                onChange={(e) => setSdt(e.target.value)}
                                className="w-[100%]"
                                placeholder={t('content.myBank.sdt')}
                                defaultValue={check.recharge[0].username || ''}
                            />
                        </div>

                        <div className={cx('form-group', { '': true })}>
                            <label className={cx('bank-title')}>{t('content.myBank.STK')}</label>
                            <input
                                disabled={check.recharge[0].stk_bank}
                                onChange={(e) => setStk(e.target.value)}
                                className="w-[100%]"
                                placeholder={t('content.myBank.vuiLongstk')}
                                defaultValue={`${
                                    check.recharge[0].stk_bank
                                        ? '*******' + String(check.recharge[0].stk_bank).slice(-3)
                                        : ''
                                }`}
                            />
                        </div>

                        <div className={cx('form-group', { '': true })}>
                            <label className={cx('bank-title')}>{t('content.myBank.tenBank')}</label>
                            <div className="w-100 py-3 text-2xl text-black">
                                {!check.recharge[0].name_bank && listBank ? (
                                    <Select options={listBank} onChange={handleChangeNameBank} />
                                ) : (
                                    <input
                                        disabled={check.recharge[0].name_bank}
                                        onChange={(e) => setNameBank(e.target.value)}
                                        className="w-[100%]"
                                        placeholder={t('content.myBank.inputTenBank')}
                                        defaultValue={check.recharge[0].name_bank || ''}
                                    />
                                )}
                            </div>
                        </div>

                        {(!check.recharge[0].name_u_bank || !check.recharge[0].stk_bank) && (
                            <div
                                onClick={() => handleAddBanking()}
                                className={cx('form-group', { 'text-center': true })}
                            >
                                <div className={cx('btn-submit', { 'text-[#fff] text-2xl': true })}>
                                    {t('content.myBank.luuLai')}
                                </div>
                            </div>
                        )}
                    </div>
                )}

                <ToastContainer
                    position="top-right"
                    autoClose={1000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </div>
        </div>
    );
}

export default MyBank;
